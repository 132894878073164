<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 17" fill="none">
        <path fill="currentColor"
            fill-rule="evenodd"
            d="M15.188 16.5 10.5 11.812l.813-.812L16 15.688l-.813.812Z"
            clip-rule="evenodd"
        />
        <path fill="currentColor"
            fill-rule="evenodd"
            d="M6.75 1.69a5.56 5.56 0 1 0 0 11.12 5.56 5.56 0 0 0 0-11.12ZM0 7.25a6.75 6.75 0 1 1 13.5 0 6.75 6.75 0 0 1-13.5 0Z"
            clip-rule="evenodd"
        />
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
